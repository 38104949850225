@import url("https://fonts.googleapis.com/css2?family=VT323&display=swap");
@import url("http://fonts.cdnfonts.com/css/doom");
@import url("https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap");
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@700&display=swap");

body {
  margin: 0;
  font-family: "VT323", monospace;
  font-variant-ligatures: none;
  -webkit-font-smoothing: none;
  -moz-osx-font-smoothing: grayscale;
  /* text-transform: uppercase; */
  background-color: rgb(0, 0, 0);
  color: white;
  z-index: 20000;
}

.videoBack {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  z-index: -1;
}

.gradient {
  background-image: linear-gradient(#000000, rgba(11, 39, 65, 0.75));
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  z-index: -1;
}

.header {
  background-color: transparent;
  font-family: "VT323", monospace;
  display: block;
  text-align: center;
  color: white;
  margin-bottom: 0px;
}

.copies h1 {
  font-size: 35px;
}

.copies p {
  font-size: 20px;
}
html {
  overflow-x: hidden;
}

.player-wrapper {
  align-items: center;
  text-align: center;
  box-shadow: #8f7001 0px 0px 0px, #8f7001 0px 0px 20px;
  border: 0;
}

.react-player {
  margin-bottom: 0;
  padding-bottom: 0;
}

.stickyContainer {
  position: sticky;
  position: -webkit-sticky;
  width: 100%;
  height: 400px;
  padding-top: 10px;
  top: 0;
  display: flex;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}

.glitch {
  text-shadow: 2px 2px 0px red, -2px -2px 0px cyan;
  filter: blur(1px);
  -webkit-filter: blur(1px);
}

.glitch-small {
  text-shadow: 1px 1px 1px red, -1px -1px 1px cyan;
  filter: blur(0.5px);
  -webkit-filter: blur(0.5px);
}

.form {
  background-color: transparent;
  font-family: "VT323", monospace;
  display: block;
  text-align: center;
  color: white;
  margin-bottom: 0px;
  padding-top: 0;
  width: 100%;
  box-shadow: #8f7001 0px 0px 0px, #8f7001 0px 0px 20px;
  border-radius: 15px;
}

.formButton {
  font-family: "VT323", monospace !important;
  font-size: 1.5rem !important;
}

.emailInput {
  font-family: "VT323", monospace;
  color: #333;
  font-size: 1.5rem;
  margin: 0 auto;
  padding: 0.5rem 0.5rem;
  border-radius: 0.2rem;
  background-color: rgb(255, 255, 255);
  border: none;
  width: 50%;
  display: block;
  border-bottom: 0.3rem solid transparent;
  transition: all 0.3s;
}

.monkeyFont {
  font-family: "IBM Plex Sans", sans-serif;
  text-decoration: underline;
  font-size: 0.75em;
  -webkit-font-smoothing: auto !important;
  -moz-osx-font-smoothing: auto !important;
}

.social-links {
  text-align: end;
  margin-top: 0.4em;
}

.social-links a {
  display: block;
  text-decoration: none;
  color: white;
  font-size: 1.2em;
  padding-top: 0.5em;
}

.social-link-text {
  display: inline-block;
  margin-left: 0.4em;
}

.footerLink {
  text-decoration: none;
  color: white;
}

.footerDisclaimer {
  color: white;
  font-size: 0.5em;
  text-align: center;
}

.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%) translateX(50%);
  transform: translateY(-50%) translateX(50%);
  right: 50%;
}

.text-badge {
  font-size: 1.6em;
  font-weight: bold;
}

.card-title {
  text-transform: uppercase;
  font-size: 3em;
  margin: 1em 0;
  text-align: center;
}
.card-description {
  font-size: 2em !important;
  margin: 1em 0;
}

.time {
  color: #ffc700;
  font-weight: bold;
}

.separator {
  height: 0.2em;
  background-color: #ffc700;
  width: 100%;
  margin-top: 1em;
}

.intro-title {
  font-size: 5em !important;
  text-align: center;
  margin-bottom: 0px;
  margin-top: 2.5em;
}

.intro-description {
  margin: 0px 0px 1em;
  text-align: center;
  font-size: 3em !important;
}
.boom-title {
  font-size: 5em;
  text-align: center;
  margin: 0.25em 0;
}

.boom-description {
  font-size: 2em;
  text-align: center;
  margin-bottom: 0;
  margin-top: 1em;
}

.faq-title {
  font-size: 5em !important;
  text-align: center;
  margin-bottom: 0px;
  margin-top: 1.5em;
}

.faq-letter {
  font-size: 3rem !important;
  margin: 0;
}

.faq-letter .q {
  color: #ffc700;
}

.faq-response {
  font-size: 2rem;
  margin: 0;
}

.presents {
  font-size: 2em !important;
  margin: 0 0.5em;
}
.extreme {
  font-size: 7em !important;
  text-align: center;
  margin-bottom: 0.5em;
  margin-top: 0.2em;
}

.zombie {
  font-size: 3em !important;
  text-align: center;
  margin-bottom: 0.5em;
  margin-top: 0.2em;
}

.form-title {
  font-size: 3em;
  margin: 40px 40px;
}

.form-description {
  font-size: 2rem;
  margin-bottom: 40px;
}
.form-text {
  font-size: 2em;
}

.footer {
  margin-top: 100px;
  margin-bottom: 100px;
  font-size: 2em;
}

.footer-image {
  height: 90px;
  width: auto;
}

.animate__animated.animate__lightSpeedInLeft {
  --animate-duration: 4s;
}

#cookies-container {
  position: fixed;
  z-index: 100;
  background-image: radial-gradient(ellipse, #05321e 0%, #050505 90%);
  box-shadow: #574400 0px 0px 0px, #705904 0px 0px 10px;
  border-radius: 1em;
}

@media only screen and (max-width: 992px) and (max-height: 1024px) {
  #cookies-container {
    left: 10%;
    bottom: 20px;
    height: auto;
    width: 80%;
  }
}

@media only screen and (min-width: 992px) {
  #cookies-container {
    right: 50px;
    bottom: 20px;
    height: auto;
    width: 20%;
  }
}

#cookies-text {
  padding: 1.6em 2em;
  font-size: 1.1em;
  line-height: 1.4em;
  margin: 0;
}

#close-cookies-button {
  cursor: pointer;
  grid-column-start: 2;
  position: absolute;
  right: 1em;
  top: 1em;
  font-family: "VT323", monospace;
}

#accept-cookies-button {
  cursor: pointer;
  border-radius: 0.75em;
  margin: 0 0% 7% 10%;
  background-color: #ffc700;
  border: 1px solid white;
  color: black;
  font-family: "VT323", monospace;
  font-size: 1.1em;
}

.cookies-link {
  color: #ffc700;
  text-decoration: none;
}

@media only screen and (max-width: 800px) {
  .card-title {
    font-size: 2em;
  }

  .card-description {
    font-size: 1em;
  }
  .intro-title {
    margin-top: 3em;
    font-size: 3em !important;
  }

  .intro-description {
    margin-top: 1em;
    font-size: 1.8em !important;
  }

  .faq-title {
    margin-top: 2em;
  }

  .boom-title,
  .faq-title {
    font-size: 3em !important;
  }

  .boom-description {
    margin-top: 1em;
    font-size: 1.8em !important;
  }

  .faq-letter {
    font-size: 2.5em !important;
  }
  .presents {
    font-size: 1.5em !important;
  }
  .extreme {
    font-size: 5em !important;
  }
  .zombie {
    font-size: 2.5em !important;
  }

  .form-title {
    font-size: 2.5em;
  }
  .form-description {
    font-size: 1.3em;
  }
  .form-text {
    font-size: 1.3em;
    padding-left: 10px;
    padding-right: 20px;
  }

  .formButton {
    font-size: 1em !important;
    width: 80%;
  }

  .formButtonBlue {
    margin-bottom: 20px !important;
    margin-right: 0 !important;
  }
  .emailInput {
    width: 80%;
  }

  .footer {
    margin-top: 50px;
    margin-bottom: 50px;
    font-size: 1.2em;
  }

  .footer-image {
    height: 50px;
    width: auto;
  }
}
